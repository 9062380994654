<template>
  <div id="ekkDetail" @click.self="close">
    <!--模态层-->
    <div
      class="detail"
      :class="showFixedBtn ? 'detail-fixed' : ''"
      v-loading="loading"
      ref="detailTem"
    >
      <!--详情dom-->
      <div class="detail-header">
        <p>{{ detailData.title }}</p>
        <i @click="close"></i>
      </div>
      <div v-if="detailData.top" class="detail-top tc flex justify-center items-center">
        <img
          :src="detailData.top.img ? detailData.top.img : default_img"
          alt=""
        />
        <p v-if="detailData.top.text">{{ detailData.top.text }}</p>
      </div>
      <dl v-for="(item, index) in detailData.infos" :key="index">
        <dt class="h">{{ item.title }}</dt>
        <dd v-for="(it, idx) in item.info" :key="idx">
          <p class="p1" style="width: 100px;">{{ it.name }}：</p>
          <div v-if="it.name == '备注' && detailData.top.openid" class="m-2" style="display: flex; flex-wrap: wrap;">
            <el-tag
              :key="tag"
              v-for="tag in it.val"
              closable
              :disable-transitions="false"
              @close="handleClose(tag,it.val)"
              :style="`margin-right: 5px; display: ${tag.length == 0 ? 'none' : 'flex'};margin-top:5px;align-items: center;`"
            
            >
            <el-tooltip :content="tag" placement="top">
              <span style="max-width: 200px;overflow: hidden;text-overflow: ellipsis;">{{ tag }}</span>
            </el-tooltip>
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              style="margin-top: 5px; width: 150px;"
              @keyup.enter.native="handleInputConfirm(it.val)"
              @blur="handleInputConfirm(it.val)"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput()"
              style="margin-top: 5px;"
              >+ 新标签</el-button
            >
          </div>
          <p v-else class="p2">
            {{ it.val }}
            <img
              v-if="it.signal >= 0"
              class="signal-img"
              :src="
                require('../assets/images/signal/signal_' + it.signal + '.png')
              "
              alt=""
            />
          </p>
          <p v-if="it.video && it.video.length > 0" class="p2">
            <el-button
              v-for="(v, i) in it.video"
              :key="i"
              size="mini"
              type="text"
              @click="handleVideo(v)"
              icon="el-icon-video-camera"
              >{{ v.name }}</el-button
            >
          </p>
        </dd>
      </dl>
      <div v-if="detailData.table">
        <ul class="tab" :class="detailData.table.tabFixed ? 'tab-fixed' : ''">
          <li class="one-tab h" v-if="detailData.table.tab.length === 1">
            {{ detailData.table.tab[0] }}
          </li>
          <li
            v-else
            :class="['tc', { ac: tabIdx === index }]"
            v-for="(item, index) in detailData.table.tab"
            :key="index"
            @click="changeTab(index)"
          >
            {{ item }}
          </li>
        </ul>
        <ekk-table
          v-if="detailData.table"
          :class="detailData.table.tabFixed ? 'header-fixed' : ''"
          :tableData="detailData.table.tableData"
          :options="detailData.table.options"
          :page="detailData.table.page"
          :feedback="detailData.table.feedback"
          @handle="
            detailData.table.detailHandle ? detailData.table.detailHandle : ''
          "
        />
      </div>
      <div v-if="detailData.btns">
        <div :class="showFixedBtn ? 'fixed-footer' : 'detail-operate'">
          <button
            v-for="(item, index) in detailData.btns"
            :key="index"
            :class="showFixedBtn ? 'fixed-footer-btn' : 'normal-operate-btn'"
            @click="item.btnEvent"
          >
            {{ item.btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ekkTable from "@/components/ekk_table";
import elTag from "element-ui";
import axios from "axios";
export default {
  name: "ekk-detail",
  props: {
    // 是否显示
    show: {
      type: Boolean,
      default: false,
    },

    // 是否loading
    loading: {
      type: Boolean,
      default: false,
    },

    /*
     * 详情数据 detailData:
     * 接收一个对象:
     *     title: String => 详情标题，必填
     *     top: Object => {  头部信息， 选填
     *           img: String =>  图片src  必填
     *           text: String => 图片文字  选填
     *     }
     *     infos: Array => [  详情数据， 选填
     *           {
     *               title: String => 小标题, 必填
     *               info: Array => [
     *                   {
     *                       name: String => 描述， 必填
     *                       value: String/Number => 值， 必填
     *                   }
     *                   ...
     *               ]
     *           },
     *           ...
     *     ]
     *     table: Object => {  // 详情数据-表格 选填
     *         tab: Array => ['tab1', 'tab2', ...],
     *         以下说明见tables组件 => './tables.vue'
     *         tableData:
     *         page
     *         feedback
     *     }
     *     btns: Array =>[ 底部操作按钮，选填
     *          {
     *              name: String => 按钮名称
     *              btnEvent: Function => 按钮对应操作事件
     *          }
     *     ]
     * */
    detailData: {
      type: Object,
      default: () => {
        return {};
      },
    },

    // 是否隐藏滚动条
    isOverflow: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showFixedBtn: false,
      detailTem: false,
      tabIdx: 0, // tab序号
      default_img: require("@/assets/images/default_avatar_90.png"),
     inputVisible: false,
        inputValue: ''
    };
  },

  watch: {
    show(val) {
      if (this.isOverflow)
        document.body.style.overflow = val ? "hidden" : "auto";
      if (this.show) this.showBtnOrNot();
    },
    loading(val) {
      document.querySelector(".detail").style.overflow = val
        ? "hidden"
        : "auto";
    },
  },

  components: {
    ekkTable
  },
  mounted() {},
  methods: {
    handleClose(tag,val) {
        let memo = val;
        memo.splice(memo.indexOf(tag), 1);
        var that = this;
        axios.post('/user_memo', {
          memo: memo.join(','),
          openid: this.detailData.top.openid
        }).then(res => {
            that.$parent.updateMemo(that.detailData.top.openid, memo.join(','));
        });
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm(val) {
      let inputValue = this.inputValue;
      if (inputValue) {

        let memo = val;
        memo.push(inputValue);

        var that = this;
        
        axios.post('/user_memo', {
          memo: memo.join(','),
          openid: this.detailData.top.openid
        }).then(res => {
            that.$parent.updateMemo(that.detailData.top.openid, memo.join(','));
        });
      }
      this.inputVisible = false;
      this.inputValue = "";
    },

    //获取detail高度，判断按钮是否悬浮
    showBtnOrNot() {
      let screenHeight = document.documentElement.clientHeight - 100,
        detailHeight = this.$refs.detailTem.getBoundingClientRect().height;
      if (detailHeight > screenHeight) {
        this.showFixedBtn = true;
      } else {
        this.showFixedBtn = false;
      }
    },
    // 关闭详情
    close() {
      this.$emit("close");
    },

    // tab切换
    changeTab(idx) {
      const that = this;
      if (idx === that.tabIdx) return;
      that.tabIdx = idx;
      that.$emit("tabChange", idx);
      this.showBtnOrNot();
    },

    handleVideo(v) {
      this.$emit("handleVideo", v);
    },
  },
};
</script>
